import Vue from 'vue'
import Vuex from 'vuex'
import { authUrl, isDevEnvironment, getUnifiedBaseUrl } from '@quaver/quaver_tools'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    data: [],
    branding: '',
    availableMonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    isAuthenticated: true,
  },
  mutations: {
    updateData(state, { data, year, month }) {
      if (state.data[year] === undefined) {
        state.data[year] = []
      }
      state.data[year][month] = data
    },
    updateBranding(state, { branding }) {
      state.branding = branding
    },
    updateAvailableMonths(state, { availableMonths }) {
      state.availableMonths = availableMonths
    },
    updateAuth(state, isAuth) {
      state.isAuthenticated = isAuth
    },
  },
  actions: {
    async checkAuth(context) {
      let url = getUnifiedBaseUrl()
      url = url.concat(authUrl)
      var creds = 'include'
      if (isDevEnvironment()) creds = 'same-origin'
      let response = await fetch(url, { method: 'GET', credentials: creds })
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      let data = await response.json()

      if (data == {} || data == undefined || data == false) {
        await context.commit('updateAuth', false)
      } else {
        await context.commit('updateAuth', true)
      }
    },
    getAvailableMonths(context, { mode, year }) {
      return new Promise((resolve, reject) => {
        let serviceUrl = `/flashservices/BrainBreaks/getBrainBreaksMonthList.aspx?mode=${mode}&year=${year}`
        let url = getUnifiedBaseUrl()
        url = url.concat(serviceUrl)
        var creds = 'include'
        if (isDevEnvironment()) creds = 'same-origin'
        fetch(url, { method: 'GET', credentials: creds }).then((response) => {
          if (!response.ok) {
            reject()
          }
          response.json().then((data) => {
            context.commit('updateAvailableMonths', { availableMonths: data })
            resolve(data)
          })
        })
      })
    },
    getBranding(context, { mode }) {
      return new Promise((resolve, reject) => {
        let brandingUrl = `/branding_${mode}.json`
        let url = '/MobileWebActivities/Applications/brain_breaks'
        url = url.concat(brandingUrl)
        var creds = 'include'
        if (isDevEnvironment()) creds = 'same-origin'
        fetch(url, { method: 'GET', credentials: creds }).then((response) => {
          if (!response.ok) {
            reject()
          }
          response.json().then((data) => {
            context.commit('updateBranding', { branding: data })
            resolve(data)
          })
        })
      })
    },
    getData(context, { year, month, mode }) {
      return new Promise((resolve, reject) => {
        // let fileUrl = `/${month}.json?mode=${mode}`
        // let url = '/MobileWebActivities/Applications/brain_breaks'
        // url = url.concat(fileUrl)
        var serviceUrl = `/flashservices/BrainBreaks/getBrainBreaksMonth.aspx?year=${year}&monthIndex=${month}&mode=${mode}`
        let url = getUnifiedBaseUrl()
        url = url.concat(serviceUrl)

        var creds = 'include'
        if (isDevEnvironment()) creds = 'same-origin'
        fetch(url, { method: 'GET', credentials: creds }).then((response) => {
          if (!response.ok) {
            reject()
          }
          response.json().then((data) => {
            context.commit('updateData', { data, year, month })
            resolve(data)
          })
        })
      })
    },
  },
  modules: {},
})
