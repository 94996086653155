<template>
  <!-- <div class="banner" role="banner" alt="">
    <h1 class="sr-only">Brain Breaks</h1>
    <img draggable="false" class="banner-image" src="../assets/MiamiDade_transBG_Header.png" alt="" />
    <div class="logos">
      <img
        v-if="branding.school_logo != ''"
        draggable="false"
        class="miami-logo"
        :src="getImageUrl(branding.school_logo)"
        alt="Miami-Dade County Public Schools logo"
      />
      <img draggable="false" class="brain-logo" src="../assets/BrainBreaks_color-01.png" alt="Brain Breaks logo" />
      <a
        :href="branding.login_url"
        v-if="branding.login_logo != '' && hasLoginLogo"
        draggable="false"
        class="quaver-login-link"
        aria-label="log in"
        role="link"
      >
        <img :src="getImageUrl(branding.login_logo)" class="quaver-login" />
      </a>
      <img
        v-if="branding.login_logo != '' && !hasLoginLogo"
        :src="getImageUrl(quaverReadyLogo)"
        alt="Quaver Ready Logo"
        draggable="false"
        class="quaver-login-link"
      />
      <b-dropdown
        size="lg"
        variant="link"
        toggle-class="text-decoration-none help-image"
        no-caret
        class="help-dropdown"
        block
        right
      >
        <b-dropdown-item
          class="how-to-use"
          href="https://users.quavered.com/hh/brainBreaks"
          role="button"
          target="_blank"
        >
          <span class="how-to-use-words">How to Use</span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div> -->
  <div class="page-banner">
    <img
      v-if="branding.school_logo != ''"
      draggable="false"
      class="miami-logo"
      :src="getImageUrl(branding.school_logo)"
      alt="Miami-Dade County Public Schools logo"
    />
    <img draggable="false" class="brain-logo" src="../assets/BrainBreaks_color-01.png" alt="Brain Breaks logo" />
    <a
      :href="branding.login_url"
      v-if="branding.login_logo != '' && hasLoginLogo"
      draggable="false"
      class="quaver-login-link"
      aria-label="log in"
      role="link"
    >
      <img :src="getImageUrl(branding.login_logo)" class="quaver-login" />
    </a>
    <img
      v-if="branding.login_logo != '' && !hasLoginLogo"
      :src="getImageUrl(quaverReadyLogo)"
      alt="Quaver Ready Logo"
      draggable="false"
      class="quaver-login-link"
    />
    <b-dropdown
      size="lg"
      variant="link"
      toggle-class="text-decoration-none help-image"
      no-caret
      class="help-dropdown"
      right
      block
    >
      <b-dropdown-item
        class="how-to-use"
        href="https://users.quavered.com/hh/brainBreaks"
        role="button"
        target="_blank"
      >
        <span class="how-to-use-words">How to Use</span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { getUnifiedBaseUrl, imageUrl } from '@quaver/quaver_tools'

export default {
  name: 'Banner',
  data() {
    return {
      quaverReadyLogo: '/media/images/1d28d4a0-8bde-40c0-8e72-fd4d1858abeb.png',
    }
  },
  props: {
    branding: Object,
    hasLoginLogo: Boolean,
  },
  methods: {
    getImageUrl: function (imageGuid) {
      if (imageGuid == undefined) return ''
      let url = getUnifiedBaseUrl()
      url = url.concat(imageUrl, imageGuid)
      return url
    },
  },
}
</script>

<style lang="scss">
.help-dropdown {
  width: 48%;
}

.help-image {
  background-image: url('../assets/help.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  aspect-ratio: 221/103;
  max-height: 70px;
  // margin-right: 10vw;
}

@media screen and (max-width: 768px) {
  .help-image {
    visibility: hidden;
  }
  .help-dropdown {
    visibility: hidden;
  }
}
</style>

<style scoped>
.page-banner {
  display: grid;
  grid-template-columns: 1fr 1fr 4fr 1fr 1.5fr;
  height: 140px;
  margin: 15px 0;
  background-image: url('../assets/MiamiDade_transBG_Header.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: fill;
  background-color: #13ade2;
  justify-content: space-evenly;
  align-content: center;
  justify-items: center;
  align-items: center;
}

.banner-image {
  height: 145px;
  width: 100vw;
  /* width: auto; */
  /* margin-left: -8px; */
  object-fit: cover;
  /* grid-area: 1 / 1 / 1 / span 4; */
  background-color: #32abe3;
}

.logos {
  display: grid;
  /* margin-top: -145px;
  margin-left: 8%; */
}

.miami-logo {
  height: 135px;
  width: auto;
}

.brain-logo {
  height: 135px;
  width: auto;
  margin: 3px -7vw 0 0;
  grid-column: 3 / 4;
  /* grid-area: 1 / 2 / span 1 / span 1; */
}

.quaver-login {
  height: 100%;
}
.quaver-login-link {
  /* height: 75px; */
  width: auto;
  height: 100px;
  /* margin: 37px 25% 0; */
  /* padding-right: 30px; */
  /* grid-area: 1 / 3 / span 1 / span 1; */
}

.how-to-use {
  width: 100%;
}
.how-to-use-words {
  width: 100%;
}

@media screen and (max-width: 1585px) {
  .quaver-login-link {
    height: 70%;
    width: auto;
    /* padding-right: 20px; */
    /* margin: 39px 9% 0; */
  }
}

@media screen and (max-width: 1500px) {
  .quaver-login-link {
    height: 75%;
    width: auto;
    /* margin: 30px -30px 0 0; */
    /* padding-right: 20px; */
    /* margin: 39px 9% 0; */
  }
  .help-dropdown {
    width: 50%;
  }
}

@media screen and (max-width: 992px) {
  .banner {
  }

  .quaver-login {
    height: 100%;
  }

  .quaver-login-link {
    height: 66%;
    width: auto;
    /* margin: 30px -30px 0 0; */
    /* padding-right: 20px; */
    /* margin: 39px 9% 0; */
  }
}

@media screen and (max-width: 768px) {
  .banner-image {
    width: 100%;
  }

  .miami-logo {
    display: none;
  }

  .quaver-login {
    display: none;
  }
  .quaver-login-link {
    display: none;
  }
}

@media screen and (max-width: 896px) {
  .quaver-login-link {
    /* height: 64%;
    width: auto; */
    /* margin: 30px -30px 0 0; */
    /* padding-right: 20px; */
    /* margin: 45px 9% 0; */
  }
}
</style>
