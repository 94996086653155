<template>
  <div>
    <div class="buttonContainer">
      <button v-bind:class="getIconNameFromType(lessonType)" v-b-modal="getModalId()" :aria-label="title">
        <b-icon :icon="getIconNameFromType(lessonType)" class="icon"></b-icon>
        <div class="buttonText">{{ title }}</div>
      </button>
    </div>
    <b-modal :id="getModalId()" size="lg" :title="getDay()" hide-footer>
      <b-container fluid class="container">
        <b-row no-gutters align-h="center" v-for="(lesson, index) in lessons" :key="index">
          <b-col class="" cols="1"><b-icon :icon="getIconNameFromType(lesson.type)"></b-icon></b-col>
          <b-col class="verbageCol">
            <h3 class="title">
              <b>{{ lesson.title }}</b>
            </h3>
            <div class="instructions" cols="5" v-for="(dataListData, index) in lesson.dataList" :key="index">
              • <span v-html="dataListData.data"></span></div
          ></b-col>
          <b-col class="thumbCol">
            <div lg="12">
              <a
                :href="lesson.thumbnailUrl"
                v-if="lesson.thumbnailUrl != ''"
                target="_blank"
                :aria-label="lesson.title + ' opens in new window'"
                ><img draggable="false" class="thumbnail" :src="lesson.thumbnailPath" alt=""
              /></a>
              <img
                draggable="false"
                class="thumbnail"
                :src="lesson.thumbnailPath"
                alt=""
                v-if="lesson.thumbnailUrl == ''"
              />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'LessonModal',
  methods: {
    getModalId: function () {
      let id = `modal_${this.index}_${this.title}_${this._uid}`
      // console.log(id)
      return id
    },
    getDay: function () {
      let day = `${this.month} ${this.dayNumber}`
      return day
    },
    getLessonData: function (dataList) {
      return dataList.foreach((index) => index.data)
    },
    getIconNameFromType: function (typeInt) {
      switch (typeInt * 1) {
        case 0:
          return 'music-note-beamed'
        case 1:
          return 'tv'
        case 2:
          return 'pencil-fill'
        case 3:
          return 'chat-fill'
        case 4:
          return 'person-fill'
        default:
          return 'bug-fill'
      }
    },
    getIconColorFromType: function (typeInt) {
      switch (typeInt * 1) {
        case 0:
          return '#99278d'
        case 1:
          return '#0d71ba'
        case 2:
          return '#f36c21'
        case 3:
          return '#00a651'
        case 4:
          return '#da1c5c'
        default:
          return '#000000'
      }
    },
  },
  props: [
    'index',
    'title',
    'lessonType',
    'lessons',
    'data',
    'dayNumber',
    'day',
    'month',
    'prevMonth',
    'nextMonth',
    'currentGrade',
  ],
}
</script>

<style scoped>
.music-note-beamed {
  color: #99278d;
  border: none;
  background-color: transparent;
}

.tv {
  color: #0d71ba;
  border: none;
  background-color: transparent;
}

.pencil-fill {
  color: #f36c21;
  border: none;
  background-color: transparent;
}

.chat-fill {
  color: #00a651;
  border: none;
  background-color: transparent;
}

.person-fill {
  color: #da1c5c;
  border: none;
  background-color: transparent;
}

.bug-fill {
  color: #000000;
  border: none;
  background-color: transparent;
}

.buttonContainer {
  display: flex;
}

.icon {
  float: left;
}

.buttonText {
  text-align: start;
  /* float: right; */
  display: flex;
  margin-top: -2px;
  padding-left: 5px;
  font-family: ZillaSlab-SemiBold;
  word-break: normal;
  font-size: 17px;
  line-height: 20px;
  font-weight: 700;
}

.title {
  font-size: 23px;
  font-family: ZillaSlab-SemiBold;
  margin: -4px 30px 5px 0;
}

.verbageCol {
  margin: 0 0 0 -25px;
}

.instructions {
  /* display: block; */
  width: 260px;
  word-break: break-word;
  font-family: OpenSans-Regular;
}

.thumbnail {
  height: 260px;
  width: auto;
}

.thumbCol {
  margin: 0 50px 30px -25px;
}

::v-deep .modal-content {
  border: 5px solid rgba(248, 178, 56, 1);
  border-radius: 0.3rem;
}

::v-deep .modal-content:before {
  content: ' ';
  position: absolute;
  z-index: -1;
  top: -11px;
  left: -11px;
  right: -11px;
  bottom: -11px;
  border: 11px solid #f7952a;
  border-radius: 1em;
}

::v-deep .modal-title {
  display: inline-block;
  font-size: 40px;
  line-height: 36px;
  letter-spacing: 0.5px;
  font-family: HouseHoliday;
  color: #fff;
  padding: 0.15em 1em;
  border-radius: 1.5em;
  background: #9a278e;
}

@media screen and (max-width: 1200px) {
  .music-note-beamed {
    display: block;
    margin: auto;
  }
  .tv {
    display: block;
    margin: auto;
  }

  .pencil-fill {
    display: block;
    margin: auto;
  }

  .chat-fill {
    display: block;
    margin: auto;
  }

  .person-fill {
    display: block;
    margin: auto;
  }

  .bug-fill {
    display: block;
    margin: auto;
  }

  .button {
    margin: 0 0 2em 0;
    /* display: block; */
    margin: 0.3em auto;
  }

  .buttonText {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .buttonText {
    text-align: start;
    float: right;
    position: absolute;
    margin-left: 5px;
    font-family: ZillaSlab-SemiBold;
    font-size: 17px;
    font-weight: 700;
  }

  .title {
    font-size: 23px;
    font-family: ZillaSlab-SemiBold;
    /* margin: 0 0 5px 0; */
  }

  .verbageCol {
    margin: 0 auto 0 auto;
  }

  .container {
    display: block;
    margin: auto;
  }

  .instructions {
    /* display: block; */
    width: 100%;
    word-break: break-word;
    font-family: OpenSans-Regular;
  }

  .thumbCol {
    /* margin: 20px 56% 20px 0px; */
    display: block;
    margin: auto;
  }

  .thumbnail {
    display: block;
    width: auto;
    /* height: 30%; */
    margin: 30px auto;
  }

  @media screen and (max-width: 448px) {
    .thumbCol {
      /* margin: 20px 56% 20px 0px; */
      /* padding: 20px 0px 20px 0px; */
    }

    .thumbnail {
      height: auto;
      width: 70vw;
    }
  }
}
</style>
