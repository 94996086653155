<template>
  <div class="grid">
    <Banner class="banner" v-bind:branding="branding" :hasLoginLogo="hasLoginLogo" v-if="branding != ''" />
    <b-container fluid class="calendarBG" :style="{ 'background-color': getBackgroundColor() }" v-if="data != ''">
      <b-row class="gradeNavRow" align-h="center" align-v="end" no-gutters>
        <b-col class="month" lg="5" offset-lg="3" align-self="end">
          <div class="monthPadding" tabindex="0" role="heading" aria-level="2">
            {{ getMonth() }} {{ getYear() }} - {{ getTitleByYearMonthIndex() }}
          </div></b-col
        >
        <b-col class="gradeNavGroup" align-self="end" lg="3">
          <div class="gradeButtons">
            <button
              class="gradeNavButton"
              @click="
                handleFilterK()
                onGradeSelect(0)
              "
              :class="[isActive ? 'gradeNotSelected' : 'gradeSelected']"
              role="tab"
              :aria-selected="isActive"
              tabindex="0"
            >
              Grades PreK-2
            </button>
            <button
              class="gradeNavButton"
              @click="
                handleFilter3()
                onGradeSelect(1)
              "
              :class="[isActive ? 'gradeSelected' : 'gradeNotSelected']"
              role="tab"
              :aria-selected="!isActive"
              tabindex="0"
            >
              Grades 3-5
            </button>
          </div>
        </b-col>
      </b-row>
      <div
        class="calendarWeek"
        role="table"
        summary="A table with days of the week across the top and themes down the left side."
        :aria-label="getMonth() + ' ' + getYear() + ' - ' + getTitleByYearMonthIndex()"
      >
        <b-row align-h="center" no-gutters role="row">
          <b-col
            md="1"
            class="theme dayHeaders"
            :style="{ 'background-color': getThemeAccentColor() }"
            tabindex="0"
            role="columnheader"
            >Theme</b-col
          >
          <b-col md="2" class="monday dayHeaders" aria-label="Monday" role="columnheader">Monday</b-col>
          <b-col md="2" class="tuesday dayHeaders" aria-label="Tuesday" role="columnheader">Tuesday</b-col>
          <b-col md="2" class="wednesday dayHeaders" aria-label="Wednesday" role="columnheader">Wednesday</b-col>
          <b-col md="2" class="thursday dayHeaders" aria-label="Thursday" role="columnheader">Thursday</b-col>
          <b-col md="2" class="friday dayHeaders" aria-label="Friday" role="columnheader">Friday</b-col>
        </b-row>
        <div role="row" v-for="(theme, index) in getThemesByYearMonthIndex()" v-bind:key="index">
          <b-row align-h="center" no-gutters>
            <b-col md="1" role="rowheader" class="themeWeek" :style="{ 'background-color': getThemeAccentColor() }">
              <div class="verticalText" tabindex="0">{{ theme.title }}</div>
            </b-col>
            <b-col
              md="2"
              class="calendarDay"
              v-for="(day, index) in theme.modules"
              :key="index"
              tabindex="0"
              role="cell"
              :aria-label="getDayOfWeek(index) + ' ' + getMonth() + ' ' + day.dayNumber"
            >
              <b-row class="dateNumber" :style="{ color: getThemeAccentColor() }" no-gutters>{{ day.dayNumber }}</b-row>
              <div class="lessonContainer">
                <div v-for="(lesson, index) in filterLessonsByGrade(day.lessons)" :key="index">
                  <LessonModal
                    class="lesson"
                    :month="getActualMonth(currentMonth, day.date)"
                    :prevMonth="getPrevMonthName()"
                    :nextMonth="getNextMonthName()"
                    :index="index"
                    :title="lesson.title"
                    :lessonType="lesson.type"
                    :data="lesson.data"
                    :day="day"
                    :lessons="filterLessonsByGrade(day.lessons)"
                    :dayNumber="day.dayNumber"
                    :currentGrade="currentGrade"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <b-row align-h="around" no-gutters class="prevNextButtons">
        <b-col md="5">
          <button
            class="prevButton"
            @click="prevMonthClicked"
            v-if="prevMonthShown"
            tabindex="0"
            :aria-label="'Go back to previous month, ' + getPrevMonthName(currentMonth, currentYear)"
          >
            &lt;&lt;{{ getPrevMonthName(currentMonth, currentYear) }}
          </button>
        </b-col>
        <b-col md="5">
          <button
            class="nextButton"
            @click="nextMonthClicked"
            v-if="nextMonthShown"
            tabindex="0"
            :aria-label="'Advance to next month, ' + getNextMonthName(currentMonth, currentYear)"
          >
            {{ getNextMonthName(currentMonth, currentYear) }}&gt;&gt;
          </button>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="statementContainer" v-if="currentMode == 0">
      <b-row align-v="center" align-h="center">
        <b-col md="7" class="">
          <h3 class="statementHeader">Vision Statement</h3>
          <p class="statementContent">
            The vision for Brain Breaks is to provide a brief time during the school day when students can have fun and
            let off steam. At the same time, Brain Breaks has been structured to follow the nine core values of the
            district, with one core value addressed each month. Each week in the month also has its theme related to the
            core value.
          </p>
          <p class="statementContent">
            Brain Breaks are filled with songs, movement, and interactive activities that will engage your students at
            different ages. As an added benefit, all the activities are designed to develop the five CASEL competencies
            of Social and Emotional Learning.
          </p>
          <p class="statementContent">
            And even better, Brain Breaks is easy to use, easy to navigate, and full of different selections you can
            make for your students. As we say at QuaverEd, this stuff is <i>Seriously Fun!</i>
          </p>
        </b-col>
        <b-col md="5">
          <img
            draggable="false"
            fluid
            class="monthlyThemes"
            src="../assets/monthlyThemes.png"
            alt="Miami Dade Core Values Themes"
          />
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="moreContainer" v-if="currentMode == 0">
      <b-row align-v="center" align-h="center">
        <b-col md="4">
          <img
            v-if="branding.quaver_ready_logo != ''"
            draggable="false"
            fluid
            class="cultureOfCaring"
            :src="getImageUrl(branding.quaver_ready_logo)"
            alt="Quaver Ready logo"
          />
        </b-col>
        <b-col md="8">
          <h3 class="statementHeader">More from QuaverReady</h3>
          <p class="moreContent">
            Brain Breaks are available to all M-DCPS teachers as a part of your district license to the QuaverReady
            program.
          </p>
          <p class="moreContent">
            To find more QuaverReady activities for your teaching this year, log in to your QuaverReady account at
            <a href="https://www.quavered.com/log-in">QuaverEd.com</a>!
          </p>
        </b-col>
      </b-row>
    </b-container>

    <Footer class="footer" />
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from '@/components/Banner.vue'
import LessonModal from '@/components/LessonModal.vue'
import Footer from '@/components/Footer.vue'
import { getUnifiedBaseUrl, imageUrl } from '@quaver/quaver_tools'

export default {
  name: 'Home',
  components: {
    Banner,
    Footer,
    LessonModal,
  },
  data() {
    return {
      data: '',
      currentMonth: 0,
      currentMonthName: '',
      currentYear: 2021,
      currentGrade: 0,
      currentMode: 0,
      branding: '',
      availableMonths: '',
      isActive: true,
      currentGradeToggle: 0,
      rewindCount: 0,
      prevMonthShown: false,
      nextMonthShown: false,
      hasLoginLogo: false,
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    }
  },
  watch: {
    data: function () {
      this.updateMonthButtons()
      console.log('data is now')
      console.dir(this.data)
    },
  },
  created() {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    this.currentMode = params.mode
    if (this.currentMode == undefined) {
      this.currentMode = 0
    }
    let today = new Date()
    this.currentMonth = today.getMonth()
    this.currentYear = today.getFullYear()

    this.$store.dispatch('checkAuth').then(() => {
      if (!this.$store.state.isAuthenticated) {
        console.log('not authenticated')
        this.hasLoginLogo = true
      }
    })

    this.initialDataFetch()
  },
  methods: {
    getDayOfWeek(index) {
      switch (index) {
        case 0:
          return 'Monday'
        case 1:
          return 'Tuesday'
        case 2:
          return 'Wednesday'
        case 3:
          return 'Thursday'
        case 4:
          return 'Friday'
        default:
          return ''
      }
    },
    updateMonthButtons: function () {
      this.prevMonthShown = this.getPrevMonthName(this.currentMonth, this.currentYear) != ''
      this.nextMonthShown = this.getNextMonthName(this.currentMonth, this.currentYear) != ''
      console.log('prev - next')
      console.log(this.prevMonthShown + ' ' + this.nextMonthShown)
    },
    filterLessonsByGrade: function (lessons) {
      return lessons.filter((item) => item.gradeIndex === this.currentGrade)
    },
    nextMonthClicked: async function () {
      console.log('nextMonthClicked')
      this.currentMonth++

      if (this.currentMonth > 11) {
        this.currentMonth = 0
        this.currentYear++
        await this.$store.dispatch('getAvailableMonths', { mode: this.currentMode, year: this.currentYear })
        this.availableMonths = this.$store.state.availableMonths
      }
      await this.fetchData(this.currentYear, this.currentMonth, this.currentMode, false, true)
    },
    prevMonthClicked: async function () {
      console.log('prevMonthClicked')
      this.currentMonth--
      if (this.currentMonth < 0) {
        this.currentMonth = 11
        this.currentYear--
        await this.$store.dispatch('getAvailableMonths', { mode: this.currentMode, year: this.currentYear })
        this.availableMonths = this.$store.state.availableMonths
      }
      await this.fetchData(this.currentYear, this.currentMonth, this.currentMode, false, true)
    },
    getImageUrl: function (imageGuid) {
      if (imageGuid == undefined) return ''
      let url = getUnifiedBaseUrl()
      url = url.concat(imageUrl, imageGuid)
      return url
    },
    getThemesByYearMonthIndex: function () {
      return this.data.themes
    },
    getTitleByYearMonthIndex: function () {
      return this.data.title
    },
    getMonth: function () {
      return this.monthNames[this.currentMonth]
    },
    getYear: function () {
      return this.currentYear
    },
    getBackgroundColor: function () {
      return this.data.backgroundColor
    },
    getThemeAccentColor: function () {
      return this.data.themeColor
    },
    getActualMonth: function (month, date) {
      let substring = date.substring(0, 7)
      let monthPlusOne = month + 1
      let actualMonth = '0'
      let prevMonth = '0'
      let nextMonth = '0'
      let monthAsString = monthPlusOne.toString()

      if (monthAsString.length != 2) {
        actualMonth = `0${monthPlusOne}`
        prevMonth = `0${monthPlusOne - 1}`
        nextMonth = `0${monthPlusOne + 1}`
      } else {
        actualMonth = `${monthPlusOne}`
        prevMonth = `${monthPlusOne - 1}`
        nextMonth = `${monthPlusOne + 1}`
      }

      let stringToMatch = `${this.currentYear}-${actualMonth}`

      let subStringAsString = substring.toString()

      if (subStringAsString != stringToMatch) {
        if (
          `0${parseInt(prevMonth)}` == subStringAsString.substring(5, 7) ||
          `${parseInt(prevMonth)}` == subStringAsString.substring(5, 7)
        ) {
          prevMonth = month - 1

          if (prevMonth < 0) {
            prevMonth = 11
          }

          return this.monthNames[prevMonth]
        }
        if (
          `0${parseInt(nextMonth)}` == subStringAsString.substring(5, 7) ||
          `${parseInt(nextMonth)}` == subStringAsString.substring(5, 7)
        ) {
          nextMonth = month + 1

          if (nextMonth > 11) {
            nextMonth = 0
          }
          return this.monthNames[nextMonth]
        }
      } else {
        return this.monthNames[this.currentMonth]
      }
    },
    handleFilterK: function () {
      this.currentGrade = 0
    },
    handleFilter3: function () {
      this.currentGrade = 1
    },
    onGradeSelect: function (grade) {
      if (grade != this.currentGradeToggle) {
        this.isActive = !this.isActive
        this.currentGradeToggle = this.currentGrade
      }
    },
    initialDataFetch: async function () {
      await this.$store.dispatch('getBranding', { mode: this.currentMode })
      this.branding = this.$store.state.branding
      console.log(this.branding)
      await this.$store.dispatch('getAvailableMonths', { mode: this.currentMode, year: this.currentYear })
      this.availableMonths = this.$store.state.availableMonths

      if (!this.availableMonths.includes(this.currentMonth)) {
        this.currentMonth = this.findNearestMonth(this.currentMonth)
      }
      await this.fetchData(this.currentYear, this.currentMonth, this.currentMode, false, true)
    },
    findNearestMonth: function (current) {
      var tempMonths = [...this.availableMonths]
      var nearestMonth = tempMonths.reduce(function (prev, curr) {
        return Math.abs(curr - current) < Math.abs(prev - current) ? curr : prev
      })
      console.log('nearestMonth: ' + nearestMonth)
      return nearestMonth
    },
    getPrevMonthName: function (month = this.currentMode) {
      let prevMonth = month - 1
      if (this.availableMonths.includes(prevMonth)) {
        return this.monthNames[prevMonth]
      } else if (prevMonth < 0) {
        prevMonth = 11
        return this.monthNames[prevMonth]
      } else {
        return ''
      }
    },
    getNextMonthName: function (month = this.currentMode) {
      let nextMonth = month + 1

      if (this.availableMonths.includes(nextMonth)) {
        return this.monthNames[nextMonth]
      } else if (nextMonth > 11) {
        nextMonth = 0
        return this.monthNames[nextMonth]
      } else {
        return ''
      }
    },
    fetchData: async function (
      year = this.currentYear,
      month = this.currentMonth,
      mode = this.currentMode,
      force = true,
      persistLocal = true
    ) {
      if (!force) {
        if (this.$store.state.data[year] != undefined && this.$store.state.data[year][month] != undefined) {
          console.log('--- using old data ---')
          if (persistLocal) this.data = this.$store.state.data[year][month]
          return
        }
      }
      console.log('--- fetching new data ---')
      this.$store.dispatch('getData', { year: year, month: month, mode: mode }).then(() => {
        if (persistLocal) this.data = this.$store.state.data[year][month]
      })
    },
  },
}
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto auto auto;
}

.banner {
  grid-area: 1 / 1 / span 1 / span 3;
  width: 100vw;
}

.gradeNavRow {
  height: auto;
  padding: 0.5rem 0 0.25rem 0;
  margin: 15px 0px 15px 0;
}

.month {
  height: 100%;
  text-align: center;
  border-radius: 50px;
  font-size: 40px;
  color: #000000;
  font-family: HouseHoliday;
  background-color: #ffffff;
  display: inline-block;
  line-height: 48px;
}

.monthPadding {
  padding: 0 1em;
}

.gradeButtons {
  float: right;
}
.gradeNavGroup {
  margin: 0 -35px 0 0;
}

.gradeNavButton {
  height: 50px;
  text-align: center;
  border-radius: 0.5em 0.5em 0 0;
  font-size: 29px;
  margin: 0 0 0 0.5rem;
  display: block;
  font-family: HouseHoliday;
  align-items: end;
  float: left;
  border: none;
}

.gradeSelected {
  background-color: #da1c5c;
  color: #ffffff;
}

.gradeNotSelected {
  background-color: #ffffff;
  color: #da1c5c;
}

.calendarBG {
  grid-area: 3 / 1 / span 1 / span 3;
  padding: 0 0 1rem 0;
  width: 100vw;
}

.calendarWeek {
  display: block;
}

.dayHeaders {
  text-align: center;
  font-family: HouseHoliday;
  color: #ffffff;
  border-radius: 0.3em;
  font-size: 30px;
  /* -webkit-text-stroke-width: 1px; */
  /* -webkit-text-stroke-color: #000000; */
  margin: 4px;
  text-shadow: -1.75px -1.75px 0 #000, 0 -1.75px 0 #000, 1.75px -1.75px 0 #000, 1.75px 0 0 #000, 1.75px 1.75px 0 #000,
    0 1.75px 0 #000, -1.75px 1.75px 0 #000, -1.75px 0 0 #000;
}

.calendarDay {
  background-color: #ffffff;
  border-radius: 0.3em;
  height: 230px;
  width: 100%;
  margin: 4px;
  /* padding: 15px; */
}

.themeWeek {
  border-radius: 0.3em;
  /* height: 189px; */
  /* width: 10%; */
  margin: 4px;
}

.verticalText {
  color: #ffffff;
  width: 180px;
  font-family: OpenSans-Regular;
  font-size: 18px;
  text-align: center;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
}

.monday {
  background-color: #00b7ad;
}

.tuesday {
  background-color: #e776a1;
}

.wednesday {
  background-color: #f47844;
}

.thursday {
  background-color: #45a2db;
}

.friday {
  background-color: #fee042;
}

.dateNumber {
  font-family: ZillaSlab-SemiBold;
  font-size: 20px;
  margin: 5px 0 0 15px;
}

.lessonContainer {
  padding: 0 10px;
  font-weight: 700;
  /* margin: 0 0; */
  display: flex;
  flex-direction: column;
  /* align-items: inherit; */
  height: 80%;
  justify-content: space-around;
}

.lesson {
  /* border: 1px solid black; */
}

.prevNextButtons {
  width: 100%;
}

.prevButton {
  height: 50px;
  text-align: center;
  border-radius: 1.5em;
  border: 3px solid #2851a2;
  color: #2851a2;
  font-family: HouseHoliday;
  font-size: 30px;
  background-color: white;
  display: block;
  float: left;
  margin: 15px -20px;
}

.nextButton {
  height: 50px;
  text-align: center;
  border-radius: 1.5em;
  border: 3px solid #2851a2;
  color: #2851a2;
  font-family: HouseHoliday;
  font-size: 30px;
  background-color: white;
  display: block;
  float: right;
  margin: 15px -20px;
}

.statementContainer {
  grid-area: 4 / 1 / span 1 / span 3;
  margin: 20px auto;
  display: block;
}

.statementHeader {
  font-family: ZillaSlab-SemiBold;
  margin: 0 0 0 20px;
  font-size: 40px;
}

.statementContent {
  font-family: OpenSans-Regular;
  font-size: 19.2px;
  display: block;
  margin-left: 20px;
  /* width: 120%; */
}

.monthlyThemes {
  height: auto;
  width: 70%;
  display: block;
  margin: auto;
  /* margin: 0 0 0 30%; */
}

.moreContainer {
  grid-area: 5 / 1 / span 1 / span 3;
  margin: auto;
  display: block;
}

.cultureOfCaring {
  width: 100%;
  /* margin-top: -10%; */
  display: block;
  margin: auto;
  padding: 20px;
}

.moreContent {
  font-family: OpenSans-Regular;
  font-size: 19.2px;
  margin-left: 20px;
  /* width: 90%; */
}

.footer {
  grid-area: 6 / 1 / span 1 / span 3;
  width: 100vw;
}

@media screen and (max-width: 1200px) {
  .lesson {
    display: block;
    padding: 0 0;
  }

  .calendarDay {
    height: 189px;
    width: 100%;
    margin: 4px;
    display: absolute;
    /* padding: 15px; */
  }

  .gradeButtons {
    float: left;
  }
}

@media screen and (max-width: 992px) {
  .month {
    display: block;
    margin: 0 1.5em;
  }

  .gradeNavButton {
    float: left;
    margin: 20px 10px 0 0;
  }
}

@media screen and (max-width: 768px) {
  .grid {
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-auto-rows: 1fr;
  }

  .nav {
    grid-area: 2 / 3 / span 1 / span 1;
    /* align-items: flex-end; */
    /* padding: 0 60px; */
  }

  .month {
    height: 100%;
    text-align: center;
    border-radius: 50px;
    font-size: 40px;
    padding: 0em 1em;
    color: #000000;
    font-family: HouseHoliday;
    background-color: #ffffff;
    display: block;
    line-height: 36px;
    margin: auto;
  }

  .gradeNavGroup {
    margin: 0px 0px 0px 5px;
  }

  .gradeNavButton {
    height: 50px;
    text-align: center;
    border-radius: 0.5em 0.5em 0 0;
    display: inline-block;
  }

  .calendarBG {
    grid-area: 3 / 1 / span 1 / span 3;
    padding: 0 1em 0;
    width: 100vw;
  }

  .calendarWeek {
    display: block;
    padding: 0 0 0 0;
  }

  .dayHeaders {
    display: none;
  }

  .calendarDay {
    background-color: #ffffff;
    border-radius: 0.3em;
    height: 189px;
    width: 100%;
    margin: 4px;
    display: absolute;
    /* padding: 15px; */
  }

  .monday {
    background-color: #00b7ad;
  }

  .tuesday {
    background-color: #e776a1;
  }

  .wednesday {
    background-color: #f47844;
  }

  .thursday {
    background-color: #45a2db;
  }

  .friday {
    background-color: #fee042;
  }

  .prevButton {
    height: 50px;
    text-align: center;
    border-radius: 1.5em;
    border: 3px solid #2851a2;
    color: #2851a2;
    font-family: HouseHoliday;
    font-size: 30px;
    background-color: white;
    display: block;
    float: none;
    margin: 15px auto;
  }

  .nextButton {
    height: 50px;
    text-align: center;
    border-radius: 1.5em;
    border: 3px solid #2851a2;
    color: #2851a2;
    font-family: HouseHoliday;
    font-size: 30px;
    background-color: white;
    display: block;
    float: none;
    margin: 15px auto;
  }

  .statementContainer {
    grid-area: 4 / 1 / span 1 / span 3;
    margin: auto;
    /* display: block; */
    /* float: none; */
  }

  .moreContainer {
    grid-area: 5 / 1 / span 1 / span 3;
    margin: auto;
    /* display: block; */
    /* float: none; */
    /* display: none; */
  }

  .banner .nav .calendarBG .statementContainer .moreContainer .footer {
    grid-column: auto;
    grid-row: auto;
    position: absolute;
  }

  @media screen and (max-width: 767.5px) {
    .themeWeek {
      border-radius: 0.3em;
      height: 100px;
      /* width: 10%; */
      margin: 4px;
    }

    .verticalText {
      margin: 0px;
      transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }
  }
}
</style>
