<template>
  <footer class="footer" role="footer" alt="">
    <img draggable="false" class="banner-image" src="../assets/MiamiDade_transBG_Header.png" alt="" />
    <div class="contentContainer">
      <div class="address" tabindex="0">
        <p>65 Music Square West</p>
        <p>Nashville, TN 37203</p>
        <p>(866) 917-3633</p>
      </div>
      <div class="icons">
        <a
          href="https://facebook.com/quaverEd"
          target="_blank"
          role="link"
          aria-label="Visit Quaver Ed's Facebook page. Opens in new window."
          tabindex="0"
          class="social-media-icon"
          ><Icon class="footer-icon" :icon="'fa-brands:facebook-f'"></Icon
        ></a>
        <a
          href="https://twitter.com/Quaver_Ed"
          target="_blank"
          role="link"
          aria-label="Visit Quaver Ed's Twitter page. Opens in new window."
          tabindex="0"
          class="social-media-icon"
        >
          <Icon class="footer-icon" icon="fa6-brands:x-twitter" />
        </a>
        <a
          href="https://www.youtube.com/QuaverMusic"
          target="_blank"
          role="link"
          aria-label="Visit Quaver Ed's YouTube page. Opens in new window."
          tabindex="0"
          class="social-media-icon"
          ><Icon class="footer-icon" :icon="'fa-brands:youtube'"></Icon
        ></a>
        <a
          href="https://www.instagram.com/Quaver_Ed/"
          target="_blank"
          role="link"
          aria-label="Visit Quaver Ed's Instagram page. Opens in new window."
          tabindex="0"
          class="social-media-icon"
          ><Icon class="footer-icon" :icon="'fa-brands:instagram'"></Icon
        ></a>
        <a
          href="https://www.pinterest.com/quavered/"
          target="_blank"
          role="link"
          tabindex="0"
          class="social-media-icon"
          aria-label="Visit Quaver Ed's Pinterest page. Opens in new window."
          ><Icon icon="fa-brands:pinterest-p" class="footer-icon"></Icon
        ></a>
      </div>
      <div class="footer-links myriadProRegular">
        <a
          href="https://www.quavered.com"
          target="_blank"
          role="link"
          tabindex="0"
          aria-label="Learn more about Quaver Ed. Opens in new window."
          >Learn more about QuaverEd
          <Icon icon="material-symbols:open-in-new-rounded" />
        </a>
        <div style="display: inline;">&nbsp;|&nbsp;</div>
        <a
          href="https://www.quavered.com/privacy-policy/"
          target="_blank"
          role="link"
          tabindex="0"
          aria-label="Visit Quaver Ed's Privacy Policy page. Opens in new window."
          >Privacy Policy
          <Icon icon="material-symbols:open-in-new-rounded" />
        </a>
        <p>Copyright © {{ currentYear() }} QuaverEd.com</p>
      </div>
    </div>
  </footer>
</template>

<script>
import { Icon } from '@iconify/vue2'
export default {
  name: 'Footer',
  components: {
    Icon,
  },
  methods: {
    currentYear() {
      const current = new Date()

      return current.getFullYear()
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  display: grid;
  height: 276px;
  margin: 1rem auto 0;
  grid-template-columns: 1fr 1fr 1fr;
  overflow: hidden;
  /* grid-template-rows: 50px 1fr; */
}

.banner-image {
  height: 276px;
  /* width: auto; */
  width: 100vw;
  object-fit: cover;
  /* margin-left: -8px; */
  grid-area: 1 / 1 / 1 / span 3;
  background-color: #32abe3;
}

.contentContainer {
  grid-area: 1 / 2 / span 1 / span 1;
  text-align: center;
  color: #ffffff;
  font-family: OpenSans-Regular;
  font-size: 18px;
  margin: 0 auto 0;
}

.address {
  margin: 40px auto 20px auto;
  line-height: 20px;
}

.icons {
  color: #ffffff;
  margin: 10px 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.social-media-icon {
  // color: #ffffff;
  // font-size: 2rem;
  border-radius: 50%;
  background-color: #2a4493;
  aspect-ratio: 1/1;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.4rem;
  margin: 0.5rem;
}

.footer-icon {
  font-size: 1.7rem;
  color: #ffffff;
  position: absolute;
  margin-top: 0.85rem;
  margin-left: 0;
  transform: translate(-50%, -50%);
}

.footer-links {
  color: #2a4493;
  padding-top: 5px;
  white-space: nowrap;
}

.footer-links a:link {
  color: #2a4493;
}

.footer-links a:visited {
  color: #2a4493;
}

.footer-links a:hover {
  color: #2a4493;
}

.footer-links a:active {
  color: #2a4493;
}

@media screen and (max-width: 992px) {
  .contentContainer {
    /* font-size: 13px; */
    width: 100%;
  }

  .address {
    margin: 15px auto 20px 0;
  }

  .icon {
    margin: 0.5rem 0.45rem;
  }
}

@media screen and (max-width: 768px) {
  .contentContainer {
    font-size: 13px;
    width: 100%;
  }

  .address {
    margin: 20px auto 20px 0;
  }

  .icon {
    font-size: 16px;
    margin: 5px;
  }
}
</style>
